<!-- =========================================================================================
    File Name: Listterms.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/term/pixinvent
========================================================================================== -->

<template>
  <div class="vx-card">
    <h4 class="pl-8 pt-6" v-if="title">{{ title }}</h4>
    <div class="p-8 pt-4">
      <div class="flex flex-wrap justify-between">
        <div class="flex">
          <vs-dropdown class="cursor-pointer" vs-trigger-click>
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium mr-2"
            >
              <span>
                {{ currentPage * paginationPageSize - (paginationPageSize - 1) }} -
                {{ termData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : termData.length }} of
                {{ totalTerms }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(10);
                  loadProductBuffer();
                "
              >
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(20);
                  loadProductBuffer();
                "
              >
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(50);
                  loadProductBuffer();
                "
              >
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(100);
                  loadProductBuffer();
                "
              >
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(150);
                  loadProductBuffer();
                "
              >
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!--- <vs-button color="primary" class="ml-2 round" type="gradient"
                      @click="uploadTerms">Upload Terms
           </vs-button> --->
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div class="flex items-center">
          <vs-input
            @input="updateSearchQuery"
            class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
            placeholder="Search..."
            v-model="searchQuery"
          />
          <vs-button
            color="primary"
            icon-pack="feather"
            icon="icon-filter"
            class=""
            v-if="filterButton"
            type="gradient"
            @click="filterPopup = !filterPopup"
            >Open Filter
          </vs-button>
        </div>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        :animateRows="true"
        :columnDefs="columnDefs"
        :components="components"
        :defaultColDef="defaultColDef"
        :enableRtl="$vs.rtl"
        :floatingFilter="true"
        :gridOptions="gridOptions"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowData="termData"
        :suppressPaginationPanel="true"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        colResizeDefault="shift"
        ref="agGridTable"
      >
      </ag-grid-vue>
      <vs-pagination :total="totalPages" v-model="currentPage" />
    </div>
    <vs-popup :active.sync="filterPopup" title="Terms Filter" id="TermFilterPopup">
      <vs-input v-model="searchQuery" @input="updateSearchQuery()" class="my-4 mt-5 w-full" size="large" label-placeholder="Term Search" />
      <vx-card class="my-4" v-if="filterSets">
        <div class="vx-row" v-if="filterSearchSetsSelected">
          <div class="vx-col w-1/5 hidden md:flex items-center align-middle">
            <vs-avatar
              :color="filterSearchSetsSelected.color"
              :text="filterSearchSetsSelected.name.charAt(0).toUpperCase()"
              class="flex-shrink-0 font-bold"
              size="80px"
            />
          </div>
          <div class="vx-col w-5/5 md:w-4/5 flex items-center align-middle">
            <div class="my-2">
              <h5 class="mb-2">{{ filterSearchSetsSelected.name }}</h5>
              <div class="text-small text-danger cursor-pointer flex items-center" @click="filterSetRemove()">
                <feather-icon icon="XIcon" svgClasses="h-5 w-5 mr-1" />
                Remove Filter
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center" v-else>
          <h2>No Set Filtered</h2>
          <vs-button
            color="primary"
            type="gradient"
            @click="
              filterPopup = false;
              filterSearchSetsPopup = true;
            "
            icon-pack="feather"
            icon="icon-term-add"
            >Select Set
          </vs-button>
        </div>
      </vx-card>
      <vs-button color="primary" type="gradient" @click="filterPopup = false" class="mt-8">Close</vs-button>
    </vs-popup>
    <vs-popup :active.sync="filterSearchSetsPopup" classContent="popup-example" title="Search for Set by Name">
      <vs-table :data="filterSearchSetsResults" :sst="true" max-items="5" search @search="filterSearchSets">
        <template slot="header">
          <h3 class="ml-4">Sets</h3>
        </template>

        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr v-for="attach in data" :key="attach.id">
            <vs-td :data="attach.id">
              {{ attach.id }}
            </vs-td>

            <vs-td :data="`${attach.name}`">
              <div class="flex align-middle items-center">
                <vs-avatar :color="attach.color" :text="attach.name.charAt(0).toUpperCase()" class="flex-shrink-0 mr-2" size="30px" />
                {{ `${attach.name}` }}
              </div>
            </vs-td>

            <vs-td>
              <vs-button
                color="primary"
                size="small"
                type="border"
                icon-pack="feather"
                icon="icon-filter"
                @click="filterSetsSelected(attach)"
              >
                Filter
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>
</template>

<script>
import TableActions from './components/TableActions.vue';
import TableCopy from './components/TableCopy';
import TableImage from './components/TableImage';
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';

export default {
  components: {
    AgGridVue,
    vSelect,
    TableActions,
    TableCopy,
    TableImage,
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    filterButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    filterSets: {
      type: Boolean,
      default: true,
      required: false,
    },
    setId: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      showArchived: false,

      termData: [],
      totalTerms: 0,

      pageValue: 0,

      searchQuery: '',

      filterPopup: false,

      filterSearchSetsPopup: false,
      filterSearchSetsResults: [],
      filterSearchSetsSelected: null,

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') {
            const value = params.value;
            return value.charAt(0).toUpperCase() + value.slice(1);
          }

          return params;
        },
      },

      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableCopy',
        },
        {
          headerName: 'Term',
          field: 'term',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableCopy',
        },
        {
          headerName: 'Definition',
          field: 'definition',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableCopy',
        },
        {
          headerName: '',
          field: 'image',
          width: 100,
          filter: false,
          cellRendererFramework: 'TableImage',
        },
        {
          headerName: 'Actions',
          width: 150,
          sortable: false,
          cellRendererFramework: 'TableActions',
          cellRendererParams: {
            loadProductBuffer: this.loadProductBuffer.bind(this),
          },
        },
      ],

      // Cell Renderer Components
      components: {
        TableActions,
        TableCopy,
        TableImage,
      },
      totalPages: 0,
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    currentPage: {
      get() {
        return this.pageValue + 1;
      },
      set(val) {
        this.pageValue = val - 1;
        this.loadProductBuffer(val);
      },
    },
  },
  methods: {
    uploadTerms() {
      this.$router.push('/a/study/questions/upload');
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery() {
      this.currentPage = 0;
    },
    loadProductBuffer(page = 0) {
      this.$emit('refresh_data');

      if (page === 0) {
        page = this.currentPage;
      }

      this.gridApi.setId = this.setId;

      this.$http
        .get(
          `study/term?pageSize=${this.paginationPageSize}&page=${page}&archived=${this.showArchived}&search=${this.searchQuery}&set=${this.setId}`,
        )
        .then((response) => {
          if (response.data) {
            this.termData = response.data.rows;
            this.totalPages = response.data.totalPages;
            this.totalTerms = response.data.total;
          }

          this.gridApi.sizeColumnsToFit();
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while loading the terms';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to load terms',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
    filterSearchSets(term) {
      this.$http.get(`flashcards/sets?search=${term}&pageSize=5`).then((response) => {
        if (response.data) {
          this.filterSearchSetsResults = response.data;
        }
      });
    },
    filterSetsSelected(set) {
      this.filterSearchSetsSelected = set;
      this.setId = set.id;
      this.filterPopup = true;
      this.filterSearchSetsPopup = false;
      this.loadProductBuffer();
    },
    filterSetRemove() {
      this.setId = '';
      this.filterSearchSetsSelected = null;
      this.loadProductBuffer();
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.filterSets) {
      this.filterSearchSets('');
    }

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  },
};
</script>
<style lang="scss">
.theme-dark {
  #TermFilterPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }

  #RegTermPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }

    .vs-component.vs-con-textarea.vs-textarea-primary {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }
}
</style>
